import styled from 'styled-components'
import bgItems from '../images/bg-items.svg'

const SubPageHero = styled.div`
text-align: center;
color: white;
background-image: url(${bgItems}), linear-gradient(160deg, #f0a354, #e43f35 50%, #c8409e);
background-size: cover;
background-repeat: repeat-y;
padding-top: 113px;
padding-bottom: 113px;
margin-top: -113px;
background-position: bottom;

.hero-squiggles {
  display: block;
  margin: 5px auto;
  max-width: 30% 
}

&::after {
    /* Hack to fix glitchy line appearing under background-image */
    content: "";
    display: block;
    background: #fff;
    height: 20px;
    top: 117px;
    position: relative;

}
`

export default SubPageHero
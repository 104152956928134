import * as React from "react"
import Menu from '../components/menu'
import Footer from '../components/footer'
import SubPageHero from '../components/sub-page-hero'
import HelmetInfo from '../components/helmet-info'
import { graphql } from "gatsby"
import styled from 'styled-components'
import squiggles from '../images/squiggles.svg'
import 'animate.css'

const Template = ({ data }) => {

  const { html } = data.markdownRemark
  const { title, date } = data.markdownRemark.frontmatter

  const MainContent = styled.main`
    max-width: 1200px;
    margin: 20px auto;
    
    h1, h2, h3, h4, h5, p {
      text-align: left!important;
      margin-bottom: 20px;      
    }

    ul {
      padding-left: 50px;
      font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
      font-weight: 700;
      color: grey;
      margin-bottom: 20px;
      line-height: 1.5;
    }

    a {
      color: #fa2b69;
    }
  `

  const DateUpdated = styled.p`
    color: white!important;
  `

return (

    <>
    <HelmetInfo />
    <Menu/>
    <SubPageHero>
            <h1 className="animate__animated animate__bounceIn">{ title }</h1>
            <DateUpdated>{ date }</DateUpdated>
            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
    </SubPageHero>
      <MainContent dangerouslySetInnerHTML={{ __html: html }}></MainContent>
    <Footer/>
    </>
  )
}

export default Template

export const query = graphql`
query PolicyPage($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        date
      }
    }
  }
`

